<template>
  <div>
    <button v-if="display" class="bg-black h-24 rounded-lg w-24 relative" @click="display = false">
      <div class="flex flex-col -mt-8">
        <div class="text-white text-center">QB SYNC</div>
        <div class="pl-5 text-left -mt-5">
          <progress-bar :options="options" :value="value" />
        </div>
      </div>
    </button>
    <button v-else class="btn-white" @click="display = true">show</button>
  </div>
</template>

<script>
import ProgressBar from 'vuejs-progress-bar';
let getProgressInterval = null;
export default {
  data: function () {
    return {
      display: false,
      options: {
        text: {
          color: '#00A0B1',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: true,
          hideText: false,
        },
        progress: {
          color: '#00A0B1',
          backgroundColor: '#005785',
          inverted: true,
        },
        layout: {
          height: 35,
          width: 50,
          verticalTextAlign: 61,
          horizontalTextAlign: 12,
          zeroOffset: 10,
          strokeWidth: 10,
          progressPadding: 0,
          type: 'circle',
        },
      },
    };
  },
  props: {},
  computed: {
    value: {
      get() {
        return this.$store.getters['admin/getQbProgress'];
      },
    },
    name: {
      get() {
        return this.$store.getters['admin/getQbName'];
      },
    },
  },
  watch: {
    display() {
      if (this.display) {
        this.fetchData();
      } else {
        this.stopSync();
      }
    },
  },
  methods: {
    fetchData() {
      getProgressInterval = setInterval(() => {
        this.$store.dispatch('admin/getQbSyncProgress');
        console.log('getData');
      }, 1000);
    },
    stopSync() {
      clearInterval(getProgressInterval);
    },
  },
  beforeDestroy() {
    this.stopSync();
  },
  components: {
    ProgressBar,
  },
};
</script>
<style scoped></style>
