<template>
  <div class="bg-gray-200 min-h-2/3-screen sm:pb-1">
    <div v-if="authorized" class="relative">
      <div class="fixed left-1 bottom-3 z-10">
        <ProgressBar />
      </div>
      <div
        class="w-full text-center pt-4 relative bg-primary h-16 flex items-center sm:justify-between shadow-md sm:shadow-none sm:text-left px-12"
        :class="getBackgroundColor"
      >
        <div class="flex items-center">
          <button
            v-if="showDestination"
            @click="determineDestination()"
            class="text-white sm:text-primary text-md font-medium absolute sm:relative left-1 sm:left-auto sm:mr-2 top-0 pt-px sm:inline-block w-24 sm:w-auto truncate sm:overflow-visible text-left"
          >
            <font-awesome-icon icon="chevron-left" class="mr-2" /> {{ destination }}
          </button>
          <h4 class="hidden sm:block font-thin" v-if="showDestination">|</h4>
          <h4
            class="sm:inline-block text-white sm:text-gray-800 text-xl mt-px sm:ml-2 sm:font-normal"
          >
            {{ $route.name }}
          </h4>
        </div>
      </div>
      <router-view class="m-4" />
      <!-- Progress bar for quickbooks syncs -->
    </div>
    <div class="flex overflow-hidden" v-else>
      <div class="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 pt-10 sm:pt-16 hidden md:block">
          <a href="/" class="inline-flex">
            <span class="sr-only">PageCustomSupply</span>
            <img class="h-32 w-auto" src="/images/icons/Logo-Secondary-full-color.svg" alt="" />
          </a>
        </div>
        <div class="flex-shrink-0 my-auto py-16 sm:py-32">
          <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
            You are not logged in
          </h1>
          <p class="mt-2 text-base text-gray-500">
            Sorry, you can only view this page with proper permissions. Please login and try again.
          </p>
          <div class="mt-6">
            <router-link
              to="/user"
              tag="button"
              class="text-base font-medium text-action hover:text-primary"
            >
              Login<span aria-hidden="true"> &rarr;</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="hidden lg:block lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
        <img class="inset-0" src="/images/static/not-logged-in.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/admin/ProgressBar';
export default {
  data() {
    return {};
  },
  computed: {
    authorized() {
      return this.$store.getters.getAuthorized;
    },
    getBackgroundColor() {
      if (this.$route.name == 'User Settings') return 'sm:bg-primary-alt';
      else return 'sm:bg-gray-200';
    },
    showDestination() {
      switch (this.$route.name) {
        case 'All Products':
        case 'All Orders':
        case 'All Users':
        case 'Layouts':
          return false;
      }
      return true;
    },
    destination() {
      const name = this.$route.name;

      if (name.includes('Product')) {
        return 'All Products';
      } else if (name.includes('User')) {
        return 'All Users';
      } else if (name === 'Company') {
        return `All Users`;
      } else if (this.$route.params.previous) {
        return `${this.$route.params.previous}'s orders`;
      } else {
        return 'All Orders';
      }
    },
  },
  watch: {},
  methods: {
    determineDestination() {
      if (window.history.length > 2 && this.$route.params.previous) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: this.destination });
      }
    },
  },
  created() {
    this.$store.dispatch('users/getCommercialUsers', '');
    this.$store.dispatch('users/getEmployees');
    this.$store.dispatch('tags/getAllTags');
  },
  components: {
    ProgressBar,
  },
};
</script>
